import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {IonIcon, IonText} from '@ionic/angular/standalone';

@Component({
  selector: 'cmn-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [IonText, IonIcon],
  standalone: true,
})
export class ValidationErrorsComponent {
  @Input() formControlRef: AbstractControl;
  @Input() validationErrors: {type: string; message: string}[];
}
